import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import HeaderTwo from '../../components/HeaderTwo.js';
import { BreadcrumbBox } from '../../components/common/Breadcrumb.js';
import FooterTwo from '../../components/FooterTwo.js';
import { Styles } from './styles/instructor.js';
import data from '../../data/message-from/data.json';

const MessagePage = () => {
    const { role } = useParams(); // role can be 'chairman', 'administrator', or 'principal'
    const [content, setContent] = useState(null);

    useEffect(() => {
        if (data[role]) {
            setContent(data[role]);
        } else {
            setContent(null); // Handle the case where the role doesn't match
        }
    }, [role]);

    if (!content) {
        return <div>Message not found.</div>; // Optionally handle the case when content is null
    }

    return (
        <Styles>
            <div className="main-wrapper instructor-details-page">

                <HeaderTwo />

                <BreadcrumbBox title={content.title} />

                <section className="instructor-details-area">
                    <Container>
                        <Row>
                            <Col md="4">
                                <div className="instructor-img">
                                    <img src={process.env.PUBLIC_URL + content.image} alt={content.name} className="img-fluid" />
                                    <ul className="list-unstyled getintouch">
                                        <li><i className="las la-phone"></i> {content.contact.phone}</li>
                                        <li><i className="lar la-envelope"></i> {content.contact.email}</li>
                                    </ul>
                                    <ul className="social list-unstyled list-inline">
                                        {content.socialLinks.facebook && <li className="list-inline-item"><a href={content.socialLinks.facebook}><i className="fab fa-facebook-f"></i></a></li>}
                                        {content.socialLinks.twitter && <li className="list-inline-item"><a href={content.socialLinks.twitter}><i className="fab fa-twitter"></i></a></li>}
                                        {content.socialLinks.linkedin && <li className="list-inline-item"><a href={content.socialLinks.linkedin}><i className="fab fa-linkedin-in"></i></a></li>}
                                        {content.socialLinks.youtube && <li className="list-inline-item"><a href={content.socialLinks.youtube}><i className="fab fa-youtube"></i></a></li>}
                                    </ul>
                                </div>
                            </Col>
                            <Col md="8">
                                <div className="instructor-content">
                                    <h4>{content.name}</h4>
                                    <span>{content.position}</span>
                                    <p>{content.message}</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <FooterTwo />

            </div>
        </Styles>
    );
}

export default MessagePage;
