import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Tabs, Tab, Form, Button } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import styled from 'styled-components';
import { Styles } from './styles/account.js';
import { useAuth } from '../../store/auth.js';

const CustomTabs = styled(Tabs)`
    .nav-item {
        width: 33.3%;
        text-align: center;
    }
    .nav-link {
        border-radius: 5px;
        background: #f8f9fa;
        transition: background 0.3s ease, color 0.3s ease;
        padding: 10px;
        font-size: 1rem;
        font-weight: 500;
        &:hover {
            background: #e2e2e2;
            color: #007bff;
        }
        &.active {
            background: #007bff;
            color: #ffffff;
            font-weight: bold;
        }
    }
`;

function Login() {
    const { storeTokenInLS, isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const [key, setKey] = useState('student');
    const [formData, setFormData] = useState({
        student: { email: '', password: '' },
        teacher: { email: '', password: '' },
        admission: { email: '', password: '' },
    });

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/home-adm-process");
        }
        const query = new URLSearchParams(location.search);
        const tab = query.get('login') || 'student';
        setKey(tab);
    }, [location, isLoggedIn, navigate]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [key]: {
                ...prevFormData[key],
                [id.split('_')[1]]: value
            }
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { email, password } = formData[key];

        if (email.trim() === "" || password.trim() === "") {
            alert("Email and Password cannot be blank.");
            return;
        }

        switch (key) {
            case 'student':
                await handleLogin('/api/student-login', email, password);
                break;
            case 'teacher':
                await handleLogin('/api/teacher-login', email, password);
                break;
            case 'admission':
                await handleLogin('http://localhost:3001/api/auth/login', email, password, true);
                break;
            default:
                break;
        }
    };

    const handleLogin = async (url, email, password, storeToken = false) => {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Login successful:', data);
                if (storeToken) {
                    storeTokenInLS(data.data.token);
                    navigate("/home-adm-process");
                } else {
                    // Handle login success for student or teacher
                    // For example, you might want to redirect them to their respective dashboards
                }
            } else {
                alert("Login Failed");
            }
        } catch (error) {
            console.error('Login error:', error);
        }
    };

    return (
        <Styles>
            <div className="main-wrapper login-page">
                <HeaderTwo />
                <BreadcrumbBox title="Log In" />
                <section className="login-area">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="login-box">
                                    <CustomTabs
                                        id="login-tabs"
                                        activeKey={key}
                                        onSelect={(k) => setKey(k)}
                                        className="mb-3"
                                    >
                                        <Tab eventKey="student" title="Student Login">
                                            <div className="login-title text-center">
                                                <h3>Student Log In</h3>
                                            </div>
                                            <form id="form_login" className="form" onSubmit={handleSubmit}>
                                                <p className="form-control">
                                                    <label htmlFor="login_email">Email</label>
                                                    <input
                                                        type="email"
                                                        placeholder="Email"
                                                        id="login_email"
                                                        value={formData.student.email}
                                                        onChange={handleInputChange}
                                                    />
                                                    <span className="login_input-msg"></span>
                                                </p>
                                                <p className="form-control">
                                                    <label htmlFor="login_password">Password</label>
                                                    <input
                                                        type="password"
                                                        placeholder="*******"
                                                        id="login_password"
                                                        value={formData.student.password}
                                                        onChange={handleInputChange}
                                                    />
                                                    <span className="login_input-msg"></span>
                                                </p>
                                                <Button type="submit" className="w-100 mt-3">Log In</Button>
                                            </form>
                                        </Tab>

                                        <Tab eventKey="teacher" title="Teacher Login">
                                            <div className="login-title text-center">
                                                <h3>Teacher Log In</h3>
                                            </div>
                                            <form id="form_login" className="form" onSubmit={handleSubmit}>
                                                <p className="form-control">
                                                    <label htmlFor="login_email">Email</label>
                                                    <input
                                                        type="email"
                                                        placeholder="Email"
                                                        id="login_email"
                                                        value={formData.teacher.email}
                                                        onChange={handleInputChange}
                                                    />
                                                    <span className="login_input-msg"></span>
                                                </p>
                                                <p className="form-control">
                                                    <label htmlFor="login_password">Password</label>
                                                    <input
                                                        type="password"
                                                        placeholder="*******"
                                                        id="login_password"
                                                        value={formData.teacher.password}
                                                        onChange={handleInputChange}
                                                    />
                                                    <span className="login_input-msg"></span>
                                                </p>
                                                <Button type="submit" className="w-100 mt-3">Log In</Button>
                                            </form>
                                        </Tab>

                                        <Tab eventKey="admission" title="Admission Process">
                                            <div className="login-title text-center">
                                                <h3>Admission Process Log In</h3>
                                            </div>
                                            <form id="form_login" className="form" onSubmit={handleSubmit}>
                                                <p className="form-control">
                                                    <label htmlFor="login_email">Email</label>
                                                    <input
                                                        type="email"
                                                        placeholder="Email"
                                                        id="login_email"
                                                        value={formData.admission.email}
                                                        onChange={handleInputChange}
                                                    />
                                                    <span className="login_input-msg"></span>
                                                </p>
                                                <p className="form-control">
                                                    <label htmlFor="login_password">Password</label>
                                                    <input
                                                        type="password"
                                                        placeholder="*******"
                                                        id="login_password"
                                                        value={formData.admission.password}
                                                        onChange={handleInputChange}
                                                    />
                                                    <span className="login_input-msg"></span>
                                                </p>
                                                <Button type="submit" className="w-100 mt-3">Log In</Button>
                                            </form>
                                        </Tab>
                                    </CustomTabs>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <FooterTwo />
            </div>
        </Styles>
    );
}

export default Login;
