import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import Search from './common/Search';
import Sidebar from './common/Sidebar';
import StickyMenu from './common/StickyMenu';
import MobileMenu from './common/MobileMenu';
import { Styles } from "./styles/header.js"; // Ensure this path is correct

const Header = () => {
    return (
        <Styles>
            {/* Topbar */}
            <section className="top-bar">
                <Container>
                    <Row>
                        <Col lg="6" md="5">
                            <div className="bar-left">
                                <ul className="list-unstyled list-inline">
                                    <li className="list-inline-item"><i className="las la-map-marker"></i>Hanjiwera Bala, Jammu and Kashmir 193121.</li>
                                    <li className="list-inline-item"><Link to={`${process.env.PUBLIC_URL}/faq`}>Have Questions</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg="6" md="7">
                            <div className="bar-right d-flex justify-content-end">
                                <ul className="list-unstyled list-inline bar-social">
                                    <li className="list-inline-item"><a href={`${process.env.PUBLIC_URL}/`}><i className="fab fa-facebook-f"></i></a></li>
                                    <li className="list-inline-item"><a href={`${process.env.PUBLIC_URL}/`}><i className="fab fa-twitter"></i></a></li>
                                    <li className="list-inline-item"><a href={`${process.env.PUBLIC_URL}/`}><i className="fab fa-linkedin-in"></i></a></li>
                                    <li className="list-inline-item"><a href={`${process.env.PUBLIC_URL}/`}><i className="fab fa-instagram"></i></a></li>
                                </ul>
                                <ul className="list-unstyled list-inline bar-lang">
                                    <li className="list-inline-item">
                                        <Dropdown>
                                            {/* <Dropdown.Toggle variant="link" id="dropdown-basic">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/us.png`} alt="English" /> English
                                                <i className="las la-angle-down"></i>
                                            </Dropdown.Toggle> */}
                                            <Dropdown.Menu>
                                                <Dropdown.Item>
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/us.png`} alt="English" /> English
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/fra.png`} alt="French" /> French
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/ger.png`} alt="German" /> German
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/spa.png`} alt="Spanish" /> Spanish
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/bra.png`} alt="Brazilian" /> Brazilian
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </li>
                                </ul>
                                <ul className="list-unstyled list-inline bar-login">
                                    <li className="list-inline-item"><Link to={`${process.env.PUBLIC_URL}/login`}><i className="las la-user"></i>Log In</Link></li>
                                    <li className="list-inline-item"><Link to={`${process.env.PUBLIC_URL}/registration`}><i className="las la-user-edit"></i>Register</Link></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Logo Area */}
            <section className="logo-area">
                <Container>
                    <Row>
                        <Col md="3">
                            <div className="logo">
                                <Link to={`${process.env.PUBLIC_URL}/`}>
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/nav-logo.png`} alt="Logo" />
                                </Link>
                            </div>
                        </Col>
                        <Col md="9">
                            <div className="logo-contact-box d-flex justify-content-end">
                                <div className="emcontact-box d-flex">
                                    <div className="box-icon">
                                        <i className="flaticon-phone-call"></i>
                                    </div>
                                    <div className="box-content">
                                        <p>Call Us Now</p>
                                        <span>+91 9596298036</span>
                                    </div>
                                </div>
                                <div className="emcontact-box d-flex">
                                    <div className="box-icon">
                                        <i className="flaticon-envelope"></i>
                                    </div>
                                    <div className="box-content">
                                        <p>Enquiry Us</p>
                                        <span>pil@pioneerinstitute.in</span>
                                    </div>
                                </div>
                                <div className="apply-btn">
                                    <Link to={`${process.env.PUBLIC_URL}/registration`}>
                                        <i className="las la-clipboard-list"></i>Apply Now
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Navbar */}
            <section className="main-menu">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="main-menu-box">
                                <div className="menu-box d-flex justify-content-between">
                                    <ul className="nav menu-nav">
                                        <li className="nav-item dropdown active">
                                            <Link className="nav-link dropdown-toggle" to={`${process.env.PUBLIC_URL}/`} data-toggle="dropdown">
                                                Home
                                            </Link>
                                        </li>
                                        {/* <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={`${process.env.PUBLIC_URL}/`} data-toggle="dropdown">
                                                Facilities <i className="las la-angle-down"></i>
                                            </Link>
                                            <ul className="dropdown list-unstyled">
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/course-grid`}>Classrooms</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/course-list`}>Library</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/course-details`}>Science Laboratory</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/course-details`}>Computer Laboratory</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/course-details`}>Multipurpose Hall</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/course-details`}>Smart Classrooms</Link>
                                                </li>
                                            </ul>
                                        </li> */}
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={`${process.env.PUBLIC_URL}/`} data-toggle="dropdown">
                                            Admissions <i className="las la-angle-down"></i>
                                            </Link>
                                            <ul className="dropdown list-unstyled">
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/admission-requirements`}>Admission Requirements</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/admission-process`}>Admission Process</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/registration`}>Apply Now</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={`${process.env.PUBLIC_URL}/`} data-toggle="dropdown">
                                                School<i className="las la-angle-down"></i>
                                            </Link>
                                            <ul className="dropdown list-unstyled">
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/mission-vision`}>Mission And Vision</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/our-history`}>History</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/message-from/chairman`}>Message from the Chairman</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/message-from/principal`}>Message from the Principal</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/message-from/administrator`}>Message from Administrator</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to={`${process.env.PUBLIC_URL}/gallery`} data-toggle="dropdown">
                                                Gallery
                                            </Link>                        
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to={`${process.env.PUBLIC_URL}/about`} data-toggle="dropdown">
                                                About Us
                                            </Link>                        
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to={`${process.env.PUBLIC_URL}/events`} data-toggle="dropdown">
                                            Notices
                                            </Link>
                                            <ul className="dropdown list-unstyled">
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/events`}>Upcoming Events</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/coming-soon`}>School News</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={`${process.env.PUBLIC_URL}/`} data-toggle="dropdown">
                                            Login <i className="las la-angle-down"></i>
                                            </Link>
                                            <ul className="dropdown list-unstyled">
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/login?login=student`}>Student Login</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/login?login=teacher`}>Teacher Login</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/login?login=admission`}>Admission Process Login</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={`${process.env.PUBLIC_URL}/contact`} data-toggle="dropdown">
                                            Contact
                                            </Link>
                                        </li>
                                        {/* <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={`${process.env.PUBLIC_URL}/`} data-toggle="dropdown">
                                                Pages <i className="las la-angle-down"></i>
                                            </Link>
                                            <ul className="dropdown list-unstyled">
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/about`}>About Us</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/login`}>Log In</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/registration`}>Registration</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/contact`}>Contact</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/faq`}>Faq</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/404`}>404</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`${process.env.PUBLIC_URL}/coming-soon`}>Coming Soon</Link>
                                                </li>
                                            </ul>
                                        </li>*/}
                                     </ul> 
                                    <ul className="nav search-cart-bar">
                                        <li className="nav-item search-box">
                                            <Search />
                                        </li>
                                        <li className="nav-item side-box">
                                            <Sidebar />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Sticky Menu */}
            <StickyMenu />

            {/* Mobile Menu */}
            <MobileMenu />
        </Styles>
    );
};

export default Header;
