import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import styled from 'styled-components';

const Styles = styled.div`
    .timeline {
        position: relative;
        padding: 4rem 0;
        margin-top: 50px;
        margin-bottom: 50px;
        max-width: 100%; /* Slightly wider */
        margin-left: auto;
        margin-right: auto;
    }

    .timeline::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
        width: 4px;
        background: linear-gradient(to bottom, #3498db, #9b59b6);
        transform: translateX(-50%);
        z-index: 1;
    }

    .timeline-item {
        position: relative;
        margin-bottom: 70px;
        width: 45%; /* Wider items */
        padding: 30px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        background-color: white;
        border-left: 8px solid #3498db;
        z-index: 2;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    .timeline-item:hover {
        transform: scale(1.04);
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.15);
    }

    .timeline-item:nth-child(odd) {
        left: 0;
        animation: fadeInLeft 1s ease-out;
    }

    .timeline-item:nth-child(even) {
        left: 50%;
        border-left: 8px solid #9b59b6;
        animation: fadeInRight 1s ease-out;
    }

    .timeline-item::after {
        content: '';
        position: absolute;
        top: 30px;
        left: -12px;
        width: 24px;
        height: 24px;
        background-color: white;
        border: 5px solid #3498db;
        border-radius: 50%;
        z-index: 3;
    }

    .timeline-item:nth-child(even)::after {
        left: auto;
        right: -12px;
        border-color: #9b59b6;
    }

    .timeline-year {
        font-size: 2.2rem;
        font-weight: bold;
        color: #3498db;
        margin-bottom: 15px;
        text-align: center;
        text-transform: uppercase;
    }

    .timeline-content h3 {
        font-size: 2.5rem;
        font-weight: bold;
        color: #2c3e50;
        margin-bottom: 20px;
    }

    .timeline-content p {
        font-size: 1.4rem;
        color: #34495e;
        line-height: 1.8;
        text-align: justify;
    }

    .timeline-content img {
        width: 100%;
        height: auto;
        border-radius: 10px;
        margin-top: 25px;
        transition: transform 0.3s ease;
    }

    .timeline-content img:hover {
        transform: scale(1.07);
    }

    @keyframes fadeInLeft {
        from { opacity: 0; transform: translateX(-60px); }
        to { opacity: 1; transform: translateX(0); }
    }

    @keyframes fadeInRight {
        from { opacity: 0; transform: translateX(60px); }
        to { opacity: 1; transform: translateX(0); }
    }

    @media (max-width: 768px) {
        .timeline::before {
            left: 20px;
        }

        .timeline-item {
            width: 100%;
            left: 0 !important;
            padding-left: 80px;
            margin-bottom: 50px;
        }

        .timeline-item:nth-child(even) {
            left: 0 !important;
            padding-left: 80px;
        }

        .timeline-item::after {
            left: 40px;
            transform: translateX(-50%);
        }
    }

    .summary-content {
        font-size: 1.8rem;
        color: #2c3e50;
        line-height: 2;
        text-align: justify;
        padding: 20px;
        background-color: #f5f5f5;
        border-radius: 12px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        margin-bottom: 50px;
    }
`;

const HistoryPage = () => {
    return (
        <Styles>
            <div className="main-wrapper history-page">
                {/* Header */}
                <HeaderTwo />

                {/* Breadcrumb */}
                <BreadcrumbBox title="Our History" />

                {/* History Section */}
                <section className="history-area py-5">
                    <Container>
                        <Row className="mb-5 text-center">
                            <Col lg="12">
                                <h2 className="section-title mb-4" style={{ fontSize: '3.5rem', fontWeight: 'bold', color: '#2c3e50' }}>Our Journey Through the Years</h2>
                                <p className="lead" style={{ fontSize: '1.5rem', color: '#7f8c8d' }}>
                                    Explore the milestones that have shaped the Pioneer Institute of Learning into a model of excellence in education.
                                </p>
                            </Col>
                        </Row>

                        {/* Summary Section */}
                        <Row>
                            <Col lg="12">
                                <div className="summary-content">
                                    <p>Since its foundation in 2008, the Pioneer Institute of Learning has evolved from a small educational establishment into a highly regarded institution. We achieved our first major milestone in 2013 when we upgraded to the secondary level. This growth was followed by the approval to offer higher secondary education in 2017, allowing us to expand our curriculum to include classes up to the 12th standard. Today, in 2023, we remain dedicated to fostering holistic development in our students, and we look forward to our future aspirations, aiming to incorporate the latest in educational technology and pedagogy.</p>
                                </div>
                            </Col>
                        </Row>

                        {/* Timeline */}
                        <Row>
                            <Col lg="12">
                                <div className="timeline">
                                    <div className="timeline-item">
                                        <div className="timeline-year">2008</div>
                                        <div className="timeline-content">
                                            <h3>Foundation</h3>
                                            <img src="/assets/images/1.png" alt="Foundation" />
                                            <p>The Pioneer Institute of Learning was established under registration number 1118/DSEK-2009, marking the beginning of a legacy.</p>
                                        </div>
                                    </div>
                                    <div className="timeline-item">
                                        <div className="timeline-year">2013</div>
                                        <div className="timeline-content">
                                            <h3>Secondary Level Upgrade</h3>
                                            <img src="/assets/images/2.png" alt="Secondary Level Upgrade" />
                                            <p>The institution was upgraded to secondary level, expanding its educational offerings to better serve the community.</p>
                                        </div>
                                    </div>
                                    <div className="timeline-item">
                                        <div className="timeline-year">2017</div>
                                        <div className="timeline-content">
                                            <h3>Higher Secondary Approval</h3>
                                            <img src="/assets/images/3.png" alt="Higher Secondary Approval" />
                                            <p>Permission was granted to run classes up to the 12th standard, solidifying our commitment to comprehensive education.</p>
                                        </div>
                                    </div>
                                    <div className="timeline-item">
                                        <div className="timeline-year">2023</div>
                                        <div className="timeline-content">
                                            <h3>Today</h3>
                                            <img src="/assets/images/4.png" alt="Today" />
                                            <p>We continue to evolve, focusing on holistic education and the development of ethical and moral values in our students.</p>
                                        </div>
                                    </div>
                                    <div className="timeline-item">
                                        <div className="timeline-year">2024</div>
                                        <div className="timeline-content">
                                            <h3>Future Aspirations</h3>
                                            <p>As we move forward, the Pioneer Institute of Learning aims to further expand its reach, incorporating the latest in educational technology and pedagogy to provide an even more comprehensive learning experience.</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Footer */}
                <FooterTwo />
            </div>
        </Styles>
    );
};

export default HistoryPage;
