import React, { Component } from 'react';
import Datas from '../data/hero/hero-image.json';
import { Container, Row, Col } from 'react-bootstrap';
import ModalVideo from 'react-modal-video';
import { Styles } from "./styles/heroImage.js";

class HeroImage extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        // Add or remove the styles based on the modal state
        const heroImageArea = document.querySelector('.hero-image-area');

        if (this.state.isOpen && !prevState.isOpen) {
            // When modal is opened, add the styles
            heroImageArea.style.zIndex = '120050';
            heroImageArea.style.backgroundColor = 'rgba(0,0,0,0.8)';
        } else if (!this.state.isOpen && prevState.isOpen) {
            // When modal is closed, remove the styles
            heroImageArea.style.zIndex = '';
            heroImageArea.style.backgroundColor = '';
        }
    }

    openModal() {
        this.setState({ isOpen: true });
    }

    closeModal() {
        this.setState({ isOpen: false });
    }

    render() {
        return (
            <Styles>
                {/* Hero Video */}
                <section className="hero-image-area">
                    <video autoPlay loop muted style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', top: 0, left: 0, zIndex: -1 }}>
                        <source src={`${process.env.PUBLIC_URL}/assets/images/pil-final-com.mp4`} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="round-shape" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${Datas.heroRoundShape})` }}></div>
                    <div className="hero-table">
                        <div className="hero-tablecell">
                            <Container>
                                <Row>
                                    <Col md="12">
                                        <div className="hero-box text-center">
                                            <h1>{Datas.heroTitle}</h1>
                                            <p>{Datas.heroSubtitle}</p>
                                            <div className="video-player">
                                                <ModalVideo
                                                    autoplay={true}
                                                    channel='youtube'
                                                    isOpen={this.state.isOpen}
                                                    videoId='7v6ov92LUOU'
                                                    onClose={this.closeModal}
                                                />
                                                <button onClick={this.openModal} className="play-button">
                                                    <i className="las la-play"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </section>
            </Styles>
        )
    }
}

export default HeroImage;
