import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoggedNavbar from './components/LoggedNavbar';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';

// Keyframes for popUpAnimation and loading spinner animation
const popUpAnimation = keyframes`
  0% { transform: scale(0.9); opacity: 0.7; }
  50% { transform: scale(1.1); opacity: 1; }
  100% { transform: scale(1); opacity: 1; }
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Styles = styled.div`
  .admission-process-page {
    font-family: 'Poppins', sans-serif;

    .admission-process-area {
      padding: 80px 0;
      background: linear-gradient(135deg, #f0f4f8 0%, #cce3f4 100%);
      min-height: 100vh;

      .admission-process-content {
        background: #ffffff;
        border-radius: 20px;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
        padding: 50px;
        margin-bottom: 60px;
        text-align: center;
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;

        .loading-spinner {
          border: 4px solid #f3f3f3; /* Light grey */
          border-top: 4px solid #12b578; /* Green */
          border-radius: 50%;
          width: 30px;
          height: 30px;
          animation: ${spinAnimation} 2s linear infinite;
          margin: 0 auto;
          margin-bottom: 15px;
        }

        .congrats-message {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          color: #1a2b49;
          font-weight: 600;
          margin-bottom: 30px;
          background: #e9f7ef;
          padding: 30px;
          border-radius: 15px;
          border: 2px solid #12b578;
          animation: ${popUpAnimation} 0.5s ease-in-out;

          .congrats-icon {
            font-size: 50px;
            color: #12b578;
            margin-bottom: 15px;
          }

          .congrats-title {
            font-size: 22px;
            font-weight: bold;
            color: #333;
            margin-bottom: 10px;
          }

          .congrats-text {
            font-size: 16px;
            color: #555;
            max-width: 700px;
            line-height: 1.5;
          }
        }

        .heading {
          margin-bottom: 40px;

          h2 {
            font-size: 32px;
            color: #1a2b49;
            font-weight: 600;
            margin-bottom: 15px;
            letter-spacing: 1px;
          }

          h4 {
            font-size: 18px;
            color: #12b578;
            font-weight: 500;
          }
        }

        .timeline {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          margin-bottom: 60px;

          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            height: 4px;
            background: #d1d8e0;
            z-index: 1;
            border-radius: 2px;
          }

          .stage {
            position: relative;
            z-index: 2;
            text-align: center;
            flex: 1;
            margin: 0 8px;

            .icon {
              width: 50px;
              height: 50px;
              background: #12b578;
              color: #ffffff;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 20px;
              margin: 0 auto 10px auto;
              transition: background 0.3s, transform 0.3s;
              border: 4px solid #ffffff;
              box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);

              &:hover {
                background: #0e3e2a;
                transform: scale(1.1);
              }
            }

            .label {
              font-size: 16px;
              color: #333;
              font-weight: 500;
              letter-spacing: 0.5px;
            }

            &.completed {
              animation: ${popUpAnimation} 0.5s ease-in-out;
            }

            &.completed .icon {
              background: #12b578;
              position: relative;

              &:before {
                content: '✓';
                position: absolute;
                top: -10px;
                right: -10px;
                background: #12b578;
                color: #fff;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                font-weight: bold;
                border: 2px solid #ffffff;
              }
            }

            &.completed .label {
              color: #12b578;
              font-weight: 700;
              text-transform: uppercase;
            }

            &.pending .icon {
              background: #d1d8e0;
            }

            &.pending .label {
              color: #555;
            }
          }
        }

        .stage-content {
          margin-top: 50px;
          text-align: center;

          h5 {
            font-size: 22px;
            color: #12b578;
            font-weight: 500;
            margin-bottom: 20px;
            letter-spacing: 0.5px;
          }

          p {
            font-size: 16px;
            color: #555;
            line-height: 1.8;
            margin-bottom: 40px;
            max-width: 700px;
            margin-left: auto;
            margin-right: auto;
            text-align: justify;
          }

          .download-button {
            display: inline-block;
            background: #12b578;
            color: #ffffff;
            padding: 12px 36px;
            border-radius: 10px;
            text-decoration: none;
            font-size: 18px;
            font-weight: 500;
            transition: background 0.3s, transform 0.3s;
            box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
            margin-top: 20px;
            border:none;
            &:hover {
              background: #0e3e2a;
              transform: translateY(-5px);
            }
          }
        }
      }

      @media (max-width: 767px) {
        padding: 60px 20px;

        .timeline {
          flex-direction: column;

          &:before {
            width: 4px;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
          }

          .stage {
            margin-bottom: 60px;
            .icon {
              margin-bottom: 10px;
            }
          }
        }

        .admission-process-content {
          padding: 40px;
        }

        .stage-content {
          margin-top: 40px;
        }
      }
    }
  }
`;

function AdmProcess() {
  const location = useLocation();
  const [showCongrats, setShowCongrats] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('register') === 'success') {
      setShowCongrats(true);
    }
  }, [location]);

  // Function to handle API call when the button is clicked
  const handleDownload = async () => {
    setLoading(true); // Set loading to true when download starts
    const token = localStorage.getItem('token');
    const url = 'http://localhost:3001/api/auth/admitCard';

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });

      if (!response.ok) {
        throw new Error('Failed to download the admit card');
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = 'admit-card.pdf';
      document.body.appendChild(a);
      a.click();
      a.remove();
      setLoading(false); // Set loading to false when download finishes
    } catch (error) {
      console.error('Error:', error);
      setLoading(false); // Set loading to false if there is an error
    }
  };

  return (
    <>
      <LoggedNavbar />
      <div className="main-wrapper contact-page">
        <Styles>
          <div className="admission-process-page">
            <section className="admission-process-area">
              <Container>
                <Row>
                  <Col>
                    <div className="admission-process-content">
                      {showCongrats && (
                        <div className="congrats-message">
                          <div className="congrats-icon">✔️</div>
                          <div className="congrats-title">Congratulations!</div>
                          <div className="congrats-text">You have successfully registered for the exam. Now, please download your admit card and stay tuned for the exam results.</div>
                        </div>
                      )}
                      <div className="heading">
                        <h2>Admission Process</h2>
                        <h4>Pioneer Institute of Learning, Kashmir</h4>
                      </div>
                      <div className="timeline">
                        <div className="stage completed">
                          <div className="icon"><i className="fa fa-user"></i></div>
                          <div className="label">Register for Exam</div>
                        </div>
                        <div className="stage pending">
                          <div className="icon"><i className="fa fa-check"></i></div>
                          <div className="label">Qualify Exam</div>
                        </div>
                        <div className="stage pending">
                          <div className="icon"><i className="fa fa-file"></i></div>
                          <div className="label">Apply for Admission</div>
                        </div>
                      </div>
                      <div className="stage-content">
                        <h5>Registration Completed!</h5>
                        <p>You have successfully registered for the exam. The next step is to qualify the exam. Stay tuned for more information. In the meantime, you can download your admit card below.</p>
                        {loading ? (
                            <>
                          <div className="loading-spinner"></div>
                          <button onClick={handleDownload} className="download-button" disabled={loading}>
                           Downloading...
                          </button>
                            </>
                        ) : (
                          <button onClick={handleDownload} className="download-button" disabled={loading}>
                            Download Admit Card
                          </button>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </Styles>
      </div>
    </>
  );
}

export default AdmProcess;
