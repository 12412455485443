import React, { useState, useEffect, useRef } from 'react';
import HeroImage from './components/HeroImage';
import ServiceBox from './components/ServiceBox';
import AboutUsTwo from './components/AboutUsTwo';
import CourseSlider from './components/CourseSlider';
import NumberCounter from './components/NumberCounter';
import TeamSlider from './components/TeamSlider';
import TestimonialSlider from './components/TestimonialSlider';
import FooterTwo from './components/FooterTwo';
import CourseFilter from './components/CourseFilter';
import CampusTour from './components/CampusTour';
import HelpArea from './components/HelpArea';
import Header from './components/Header';


const HomeTwo = () => {
 

    return (
        <div className="main-wrapper">
            {/* <LoadingBar
                color='#f11946'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
                ref={loadingBarRef}
                height={4}
            /> */}

            {/* Other components */}
            <Header />
            <HeroImage />
            <ServiceBox />
            <AboutUsTwo />
            <CourseSlider />
            <NumberCounter />
            <CourseFilter />
            <TestimonialSlider />
            <TeamSlider />
            <HelpArea />
            <CampusTour />
            <FooterTwo />
            
        </div>
    );
}

export default HomeTwo;
