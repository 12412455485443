import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import styled from 'styled-components';

const Styles = styled.div`
    .admission-process-page {
        font-family: 'Poppins', sans-serif;

        .admission-process-area {
            padding: 80px 0;
            background: linear-gradient(135deg, #f6f9fc 0%, #e9eff4 100%);

            .admission-process-content {
                background: #ffffff;
                border-radius: 15px;
                box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
                padding: 50px;
                margin-bottom: 60px;

                .heading {
                    margin-bottom: 40px;
                    text-align: center;

                    h2 {
                        font-size: 36px;
                        color: #1a2b49;
                        font-weight: 600; /* Reduced font weight */
                        margin-bottom: 15px;
                        letter-spacing: 1px;
                    }

                    h4 {
                        font-size: 20px;
                        color: #12b578;
                        font-weight: 500;
                    }
                }

                .timeline {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                    margin-bottom: 60px;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        right: 0;
                        height: 6px;
                        background: #d1d8e0;
                        z-index: 1;
                        border-radius: 3px;
                    }

                    .stage {
                        position: relative;
                        z-index: 2;
                        text-align: center;
                        flex: 1;
                        margin: 0 8px;

                        .icon {
                            width: 60px;
                            height: 60px;
                            background: #12b578;
                            color: #ffffff;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 24px;
                            margin: 0 auto 15px auto;
                            transition: background 0.3s, transform 0.3s;
                            border: 4px solid #ffffff;
                            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);

                            &:hover {
                                background: #0e3e2a;
                                transform: scale(1.1);
                            }
                        }

                        .label {
                            font-size: 18px;
                            color: #333;
                            font-weight: 500;
                            letter-spacing: 0.5px;
                        }

                        &.active .icon,
                        &.completed .icon {
                            background: #0e3e2a;
                            transform: scale(1.15);
                        }

                        &.completed .label {
                            color: #0e3e2a;
                        }
                    }
                }

                .stage-content {
                    margin-top: 50px;
                    text-align: center;

                    h5 {
                        font-size: 24px;
                        color: #12b578;
                        font-weight: 500;
                        margin-bottom: 20px;
                        letter-spacing: 0.5px;
                    }

                    p {
                        font-size: 16px;
                        color: #555;
                        line-height: 1.8;
                        margin-bottom: 40px;
                        max-width: 700px;
                        margin-left: auto;
                        margin-right: auto;
                        text-align: justify;
                    }

                    a {
                        display: inline-block;
                        background: #12b578;
                        color: #ffffff;
                        padding: 14px 40px;
                        border-radius: 10px;
                        text-decoration: none;
                        font-size: 20px;
                        font-weight: 500;
                        transition: background 0.3s, transform 0.3s;
                        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);

                        &:hover {
                            background: #0e3e2a;
                            transform: translateY(-5px);
                        }
                    }
                }

                .steps-description {
                    margin-top: 50px;
                    text-align: center;

                    .step {
                        margin-bottom: 40px;

                        h6 {
                            font-size: 22px;
                            color: #12b578;
                            font-weight: 500;
                            margin-bottom: 15px;
                        }

                        p {
                            font-size: 16px;
                            color: #555;
                            line-height: 1.8;
                            max-width: 700px;
                            margin-left: auto;
                            margin-right: auto;
                            text-align: justify;
                        }
                    }
                }
            }

            @media (max-width: 767px) {
                padding: 60px 0;

                .timeline {
                    flex-direction: column;

                    &:before {
                        width: 4px;
                        height: 100%;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                    .stage {
                        margin-bottom: 60px;
                        .icon {
                            margin-bottom: 10px;
                        }
                    }
                }

                .admission-process-content {
                    padding: 40px;
                }

                .stage-content {
                    margin-top: 40px;
                }
            }
        }

        footer {
            background: #12b578;
            color: #ffffff;
            padding: 30px 0;
            text-align: center;
            p {
                margin: 0;
                font-size: 16px;
                line-height: 1.7;
                font-weight: 500;
            }
        }
    }
`;

class AdmissionProcess extends Component {
    render() {
        return (
            <Styles>
                <div className="main-wrapper admission-process-page">
                    <HeaderTwo />
                    <BreadcrumbBox title="Admission Process" />
                    <section className="admission-process-area">
                        <Container>
                            <Row>
                                <Col>
                                    <div className="admission-process-content">
                                        <div className="heading">
                                            <h2>Admission Process</h2>
                                            <h4>Pioneer Institute of Learning, Kashmir</h4>
                                        </div>
                                        <div className="timeline">
                                            <div className="stage active">
                                                <div className="icon"><i className="fa fa-user"></i></div>
                                                <div className="label">Register for Exam</div>
                                            </div>
                                            <div className="stage">
                                                <div className="icon"><i className="fa fa-check"></i></div>
                                                <div className="label">Qualify Exam</div>
                                            </div>
                                            <div className="stage">
                                                <div className="icon"><i className="fa fa-file"></i></div>
                                                <div className="label">Apply for Admission</div>
                                            </div>
                                        </div>
                                        <div className="stage-content">
                                            <h5>Register for the Entrance Exam</h5>
                                            <p>Begin your journey by registering for the entrance exam. Secure your future by taking the first step towards quality education. Ensure that all your details are accurate, and don’t miss the registration deadlines. Click the link below to start your registration process.</p>
                                            <Link to="/register">Register Now</Link>
                                        </div>

                                        <div className="steps-description">
                                            <div className="step">
                                                <h6>Step 1: Register for Exam</h6>
                                                <p>Register online through our website by providing the necessary information. Ensure you have all the required documents ready. Once registered, you will receive an acknowledgment and details about the exam schedule.</p>
                                            </div>
                                            <div className="step">
                                                <h6>Step 2: Qualify Exam</h6>
                                                <p>Appear for the entrance exam on the scheduled date. The exam will assess your knowledge and skills relevant to the course you are applying for. Results will be communicated via email and displayed on our website.</p>
                                            </div>
                                            <div className="step">
                                                <h6>Step 3: Apply for Admission</h6>
                                                <p>If you qualify for the exam, you can proceed with the admission process. Fill out the admission form with all the required details and submit the necessary documents. Pay the admission fee to secure your spot.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <FooterTwo />
                </div>
            </Styles>
        );
    }
}

export default AdmissionProcess;
