import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import Pagination from '../../components/Pagination';
import FooterTwo from '../../components/FooterTwo';
import styled from 'styled-components';

const eventsData = [
    {
        eventTitle: "Overnight Camp for Grade VII",
        eventDate: "26 Jul, 2024",
        eventTime: "10:00 AM - 4:00 PM",
        eventLocation: "DPS Campus",
        eventDesc: "An exciting overnight camp for Grade VII students with various activities aimed at fostering teamwork and leadership.",
        eventImg: "/assets/images/1.png",
        eventLink: "/events/camp-grade-vii"
    },
    {
        eventTitle: "Overnight Camp for Grade VI",
        eventDate: "05 Jul, 2024",
        eventTime: "9:00 AM - 3:00 PM",
        eventLocation: "DPS Campus",
        eventDesc: "An adventurous overnight camp filled with educational and recreational activities for Grade VI students.",
        eventImg: "/assets/images/2.png",
        eventLink: "/events/camp-grade-vi"
    },
    {
        eventTitle: "Art Exhibition 2024",
        eventDate: "15 Sep, 2024",
        eventTime: "11:00 AM - 2:00 PM",
        eventLocation: "School Art Gallery",
        eventDesc: "A display of student artworks from the 2024 academic year.",
        eventImg: "/assets/images/3.png",
        eventLink: "/events/art-exhibition"
    },
    {
        eventTitle: "Science Fair 2024",
        eventDate: "10 Oct, 2024",
        eventTime: "9:00 AM - 1:00 PM",
        eventLocation: "School Auditorium",
        eventDesc: "Showcase of innovative science projects by students.",
        eventImg: "/assets/images/4.png",
        eventLink: "/events/science-fair"
    }
];

const notificationsData = [
    {
        notificationTitle: "List of buses plying for staff on Saturday",
        notificationDate: "23 Aug, 2024",
        notificationDesc: "Check your bus routes for staff operating on Saturday, 24th August 2024.",
        notificationImg: "https://media.dpssrinagar.com/v2/media/2017/05/Tata-Starbus-Ultra-Skool-buses-by-Tata.jpg",
        notificationLink: "/notifications/buses-staff"
    },
    {
        notificationTitle: "List of buses plying for staff on Thursday",
        notificationDate: "14 Aug, 2024",
        notificationDesc: "Updated bus routes available for staff on Thursday, 15th August 2024.",
        notificationImg: "https://media.dpssrinagar.com/v2/media/2017/05/Tata-Starbus-Ultra-Skool-buses-by-Tata.jpg",
        notificationLink: "/notifications/buses-thursday"
    },
    {
        notificationTitle: "School Reopening Notification",
        notificationDate: "01 Sep, 2024",
        notificationDesc: "School will reopen on 5th September after summer break.",
        notificationImg: "https://media.dpssrinagar.com/v2/media/2017/05/Tata-Starbus-Ultra-Skool-buses-by-Tata.jpg",
        notificationLink: "/notifications/school-reopening"
    },
    {
        notificationTitle: "Examination Schedule Released",
        notificationDate: "10 Sep, 2024",
        notificationDesc: "The schedule for mid-term examinations has been released.",
        notificationImg: "/assets/images/5.png",
        notificationLink: "/notifications/exam-schedule"
    }
];

const Styles = styled.div`
    .event-page-area {
        padding: 60px 0;

        .section-title {
            font-size: 2rem;
            font-weight: 700;
            color: #333;
            margin-bottom: 30px;
            text-transform: uppercase;
            border-bottom: 2px solid #3498db;
            padding-bottom: 10px;
        }

        .events-section {
            .custom-table {
                width: 100%;
                margin-bottom: 30px;
                border-collapse: separate;
                border-spacing: 0 15px;

                thead {
                    background-color: #3498db;
                    color: white;

                    th {
                        padding: 15px;
                        text-align: left;
                        font-size: 1.2rem;
                    }
                }

                tbody {
                    tr {
                        background-color: #fff;
                        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
                        transition: all 0.3s ease;
                        border-radius: 10px;
                        
                        &:hover {
                            box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
                            transform: scale(1.02);
                        }

                        td {
                            padding: 20px;
                            vertical-align: middle;
                        }

                        .event-img img {
                            width: 100px;
                            height: auto;
                            border-radius: 8px;
                        }

                        .event-title {
                            font-size: 1.25rem;
                            font-weight: 600;
                            color: #3498db;

                            &:hover {
                                color: #2980b9;
                                text-decoration: underline;
                            }
                        }

                        .event-desc {
                            font-size: 1rem;
                            color: #555;
                        }

                        .event-date,
                        .event-time-location {
                            font-size: 0.9rem;
                            color: #777;
                        }

                        .event-action {
                            text-align: right;

                            a {
                                font-size: 0.875rem;
                                color: #fff;
                                background: #3498db;
                                padding: 8px 15px;
                                border-radius: 5px;
                                transition: background 0.3s ease;

                                &:hover {
                                    background: #2980b9;
                                }
                            }
                        }
                    }
                }
            }
        }

        .notifications-section {
            .custom-table {
                width: 100%;
                margin-bottom: 30px;
                border-collapse: separate;
                border-spacing: 0 10px;

                thead {
                    background-color: #2c3e50;
                    color: white;

                    th {
                        padding: 12px;
                        text-align: left;
                        font-size: 1rem;
                    }
                }

                tbody {
                    tr {
                        background-color: #f9f9f9;
                        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                        transition: all 0.2s ease;
                        border-radius: 8px;
                        
                        &:hover {
                            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
                        }

                        td {
                            padding: 15px;
                            vertical-align: middle;
                        }

                        .event-img img {
                            width: 80px;
                            height: auto;
                            border-radius: 5px;
                        }

                        .event-title {
                            font-size: 1rem;
                            font-weight: 600;
                            color: #2c3e50;

                            &:hover {
                                color: #1a242f;
                                text-decoration: underline;
                            }
                        }

                        .event-desc {
                            font-size: 0.9rem;
                            color: #666;
                        }

                        .event-date {
                            font-size: 0.85rem;
                            color: #555;
                        }
                    }
                }
            }
        }

        @media (max-width: 768px) {
            .custom-table thead {
                display: none;
            }

            .custom-table tbody tr {
                display: flex;
                flex-direction: column;
                margin-bottom: 15px;
            }

            .custom-table tbody tr td {
                display: flex;
                justify-content: space-between;
                padding: 10px;
                border-bottom: 1px solid #ddd;

                &:last-child {
                    border-bottom: none;
                }
            }

            .custom-table tbody tr td::before {
                content: attr(data-label);
                font-weight: 600;
                color: #333;
            }

            .event-img img {
                width: 80px;
            }
        }
    }
`;

const EventsAndNotifications = () => {
    return (
        <Styles>
            <div className="main-wrapper event-page">

                {/* Header */}
                <HeaderTwo />

                {/* Breadcrumb */}
                <BreadcrumbBox title="Events & Notifications" />

                {/* Events and Notifications Area */}
                <section className="event-page-area">
                    <Container>
                        <Row>
                            <Col lg="6" md="12" className="events-section">
                                <h3 className="section-title">Latest Events</h3>
                                <Table className="custom-table">
                                    <thead>
                                        <tr>
                                            <th>Image</th>
                                            <th>Details</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {eventsData.map((data, i) => (
                                            <tr key={i}>
                                                <td className="event-img">
                                                    <Link to={data.eventLink}><img src={data.eventImg} alt={data.eventTitle} /></Link>
                                                </td>
                                                <td>
                                                    <Link to={data.eventLink} className="event-title">{data.eventTitle}</Link>
                                                    <p className="event-desc">{data.eventDesc}</p>
                                                    <p className="event-time-location"><i className="las la-clock"></i> {data.eventTime} | <i className="las la-map-marker"></i> {data.eventLocation}</p>
                                                </td>
                                                <td className="event-date">{data.eventDate}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>

                            <Col lg="6" md="12" className="notifications-section">
                                <h3 className="section-title">Notifications</h3>
                                <Table className="custom-table">
                                    <thead>
                                        <tr>
                                            <th>Image</th>
                                            <th>Details</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {notificationsData.map((data, i) => (
                                            <tr key={i}>
                                                <td className="event-img">
                                                    <Link to={data.notificationLink}><img src={data.notificationImg} alt={data.notificationTitle} /></Link>
                                                </td>
                                                <td>
                                                    <Link to={data.notificationLink} className="event-title">{data.notificationTitle}</Link>
                                                    <p className="event-desc">{data.notificationDesc}</p>
                                                </td>
                                                <td className="event-date">{data.notificationDate}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                        {/* Pagination */}
                        <Row>
                            <Col md="12" className="text-center mt-4">
                                <Pagination />
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Footer */}
                <FooterTwo />

            </div>
        </Styles>
    );
}

export default EventsAndNotifications;
