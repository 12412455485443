import React, { useEffect } from 'react';

const GoogleMap = () => {
    useEffect(() => {
        // Load Leaflet CSS
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = "https://unpkg.com/leaflet@1.9.4/dist/leaflet.css";
        document.head.appendChild(link);

        // Load Leaflet JS
        const script = document.createElement("script");
        script.src = "https://unpkg.com/leaflet@1.9.4/dist/leaflet.js";
        script.onload = () => {
            const L = window.L;
            const map = L.map('map').setView([34.143085, 74.578220], 13);

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(map);

            L.marker([34.143085, 74.578220]).addTo(map)
                .bindPopup('Pioneer Institute of Learning')
                .openPopup();
        };
        document.body.appendChild(script);

        // Cleanup function to remove added elements when component unmounts
        return () => {
            document.head.removeChild(link);
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div style={{ width: '100%', height: '500px' }}>
            <div id="map" style={{ width: '100%', height: '100%' }}></div>
        </div>
    );
};

export default GoogleMap;
