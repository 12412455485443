import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import styled from 'styled-components';

const Styles = styled.div`
    .admission-requirements-page {
        .admission-requirements-area {
            padding: 80px 0;
            background: #f9f9f9;

            .admission-requirements-content {
                background: #fff;
                border-radius: 10px;
                box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
                padding: 60px;
                margin-bottom: 60px;
                transition: all 0.3s ease-in-out;

                &:hover {
                    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15);
                }

                .heading {
                    margin-bottom: 40px;
                    text-align: center;
                    h2 {
                        font-size: 36px;
                        color: #333;
                        font-weight: 700;
                        margin-bottom: 10px;
                        text-transform: uppercase;
                    }
                    h4 {
                        font-size: 20px;
                        color: #555;
                        font-weight: 400;
                    }
                }

                h5 {
                    color: #444;
                    font-weight: 600;
                    margin: 50px 0 20px 0;
                    position: relative;
                    font-size: 22px;
                    &:before {
                        content: "";
                        width: 50px;
                        height: 3px;
                        background: #007bff;
                        position: absolute;
                        bottom: -10px;
                        left: 0;
                    }
                }

                p {
                    font-size: 16px;
                    color: #666;
                    line-height: 1.8;
                    margin-bottom: 30px;
                    text-align: justify;
                }

                ul {
                    list-style: none;
                    padding-left: 0;
                    margin-top: 20px;

                    li {
                        font-size: 16px;
                        color: #555;
                        line-height: 1.8;
                        position: relative;
                        padding-left: 35px;
                        margin-bottom: 20px;

                        i {
                            color: #007bff;
                            position: absolute;
                            left: 0;
                            top: 2px;
                            font-size: 20px;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .apply-now-button {
                    text-align: center;
                    margin-top: 40px;
                    a {
                        background: #007bff;
                        color: #fff;
                        padding: 15px 40px;
                        border-radius: 5px;
                        font-size: 18px;
                        text-decoration: none;
                        font-weight: 600;
                        transition: background 0.3s, transform 0.3s;

                        &:hover {
                            background: #0056b3;
                            transform: translateY(-2px);
                        }
                    }
                }

                .contact-info {
                    margin-top: 60px;
                    h5 {
                        color: #444;
                        font-weight: 600;
                        margin-bottom: 25px;
                        position: relative;
                        font-size: 22px;
                        &:before {
                            content: "";
                            width: 50px;
                            height: 3px;
                            background: #007bff;
                            position: absolute;
                            bottom: -10px;
                            left: 0;
                        }
                    }

                    p, li {
                        font-size: 16px;
                        color: #555;
                        line-height: 1.8;
                    }

                    a {
                        color: #007bff;
                        font-weight: 500;
                        transition: color 0.3s;

                        &:hover {
                            color: #0056b3;
                            text-decoration: underline;
                        }
                    }
                }
            }

            .admission-sidebar {
                padding-left: 20px;

                @media (max-width: 767px) {
                    padding-left: 0;
                    margin-top: 40px;
                }

                .widget {
                    margin-bottom: 40px;
                    background: #fff;
                    border-radius: 10px;
                    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
                    padding: 20px;

                    h4 {
                        color: #333;
                        font-weight: 600;
                        margin-bottom: 20px;
                    }

                    ul {
                        list-style: none;
                        padding-left: 0;
                        margin: 0;

                        li {
                            margin-bottom: 15px;
                            font-size: 16px;
                            color: #666;

                            a {
                                color: #007bff;
                                transition: color 0.3s;
                                text-decoration: none;

                                &:hover {
                                    color: #28a745;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }

                    @media (max-width: 767px) {
                        padding: 15px;
                    }
                }
            }

            @media (max-width: 767px) {
                padding: 60px 0;
            }
        }

        footer {
            background: #333;
            color: #fff;
            padding: 30px 0;
            text-align: center;
            p {
                margin: 0;
                font-size: 14px;
                line-height: 1.6;
            }
        }
    }
`;

class AdmissionRequirements extends Component {
    render() {
        return (
            <Styles>
                <div className="main-wrapper admission-requirements-page">
                    <HeaderTwo />
                    <BreadcrumbBox title="Admission Requirements" />
                    <section className="admission-requirements-area">
                        <Container>
                            <Row>
                                <Col lg="9" md="8" sm="12">
                                    <div className="admission-requirements-content">
                                        <div className="heading">
                                            <h2>Admission Requirements</h2>
                                            <h4>Pioneer Institute of Learning, Kashmir</h4>
                                        </div>
                                        <div className="admission-overview">
                                            <h5>General Requirements</h5>
                                            <p>To apply for admission to Pioneer Institute of Learning in Kashmir, applicants must meet the following criteria:</p>
                                            <ul className="list-unstyled">
                                                <li><i className="fa fa-check"></i> Completion of the previous academic year with a minimum required percentage (typically 50% or higher).</li>
                                                <li><i className="fa fa-check"></i> Submission of the completed admission form available from the school's administration office or website.</li>
                                                <li><i className="fa fa-check"></i> Birth certificate issued by a competent authority.</li>
                                                <li><i className="fa fa-check"></i> Transfer certificate (TC) from the previous school.</li>
                                                <li><i className="fa fa-check"></i> Recent passport-sized photographs of the applicant.</li>
                                                <li><i className="fa fa-check"></i> Aadhar card of the applicant and parents for identity verification.</li>
                                                <li><i className="fa fa-check"></i> Medical certificate stating the child’s health status and immunization records.</li>
                                            </ul>
                                        </div>

                                        <div className="additional-requirements">
                                            <h5>Additional Requirements for Higher Classes (8th Grade and Above)</h5>
                                            <p>Students applying for admission to higher classes must provide additional documents:</p>
                                            <ul className="list-unstyled">
                                                <li><i className="fa fa-check"></i> Mark sheets of the last two academic years.</li>
                                                <li><i className="fa fa-check"></i> Character certificate from the previous school.</li>
                                                <li><i className="fa fa-check"></i> Migration certificate if applicable (for students from other states or boards).</li>
                                            </ul>
                                        </div>

                                        <div className="admission-process">
                                            <h5>Application Process</h5>
                                            <p>The admission process at Pioneer Institute of Learning involves the following steps:</p>
                                            <ul className="list-unstyled">
                                                <li><i className="fa fa-check"></i> Collect the admission form from the school or download it from the website.</li>
                                                <li><i className="fa fa-check"></i> Fill out the form accurately and attach all required documents.</li>
                                                <li><i className="fa fa-check"></i> Submit the form and documents to the school’s admission office.</li>
                                                <li><i className="fa fa-check"></i> Attend the interaction/interview session with the school authorities (for certain grades).</li>
                                                <li><i className="fa fa-check"></i> Await the final admission list, which will be displayed on the school notice board or communicated via phone/email.</li>
                                                <li><i className="fa fa-check"></i> Complete the fee payment within the stipulated time to confirm the admission.</li>
                                            </ul>
                                        </div>

                                        <div className="apply-now-button">
                                            <Link to="/registration">Apply Now</Link>
                                        </div>

                                        <div className="contact-info">
                                            <h5>Contact Information</h5>
                                            <p>For any inquiries regarding the admission process, please contact us:</p>
                                            <ul className="list-unstyled">
                                                <li>Email: <a href="mailto:admissions@pioneer-institute.edu">admissions@pioneer-institute.edu</a></li>
                                                <li>Phone: +91-1234567890</li>
                                                <li>Address: 1234 Education Lane, Srinagar, Kashmir, J&K</li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="3" md="4" sm="12">
                                    <div className="admission-sidebar">
                                        <Row>
                                            <Col md="12">
                                                <div className="widget">
                                                    <h4>Important Links</h4>
                                                    <ul>
                                                        <li><Link to="/admission-form">Admission Form</Link></li>
                                                        <li><Link to="/fee-structure">Fee Structure</Link></li>
                                                        <li><Link to="/school-timetable">School Timetable</Link></li>
                                                        <li><Link to="/contact">Contact Us</Link></li>
                                                    </ul>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <FooterTwo />
                </div>
            </Styles>
        );
    }
}

export default AdmissionRequirements;
