import React, { useRef, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import ScrollToTop from './helper/ScrollToTop';
import { GlobalStyle } from "./components/common/styles/global.js";
import HomeTwo from './HomeTwo';
import About from './pages/about/About';
import CourseGrid from './pages/history/CourseGrid';
import CourseList from './pages/history/CourseList';
import Instructor from './pages/message-from/Instructors.js';
import MessageFromChairman from './pages/message-from/MessageFromChairman.js';
import Gallery from './pages/gallery/Gallery';
import Events from './pages/AdmissionRequirements/Events.js';
import AdmissionRequirements from './pages/AdmissionRequirements/AdmissionRequirements.js';
import Login from './pages/account/Login';
import Register from './pages/account/Register';
import Contact from './pages/contact/Contact';
import Faq from './pages/faq/Faq';
import PageNotFound from './pages/404/PageNotFound';
import ComingSoon from './pages/comingsoon/ComingSoon';
import BlogClassic from './pages/mission/BlogClassic';
import BlogGrid from './pages/mission/BlogGrid';
import MissionVision from './pages/mission/mission-vision';
import Product from './pages/shop/Products';
import ProductDetails from './pages/shop/ProductDetails';
import Cart from './pages/shop/Cart';
import AdmProcess from './pages/admission/admissionProcess.js';
import Logout from './pages/Logout.js';
import HistoryPage from './pages/history/our-history.js';
import AdmissionProcess from './pages/AdmissionRequirements/AdmissionProcess.js';
import PrivateRoute from './components/PrivateRoute.js';
import { AuthProvider } from './store/auth.js';

function App() {
    const loadingBarRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        loadingBarRef.current.continuousStart();
        setTimeout(() => {
            loadingBarRef.current.complete();
        }, 800);
    }, [location]);

    return (
        <AuthProvider>
            <LoadingBar color='#f11946' ref={loadingBarRef} height={2} />
            <GlobalStyle />
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<HomeTwo />} />
                <Route path="/about" element={<About />} />
                <Route path="/course-grid" element={<CourseGrid />} />
                <Route path="/course-list" element={<CourseList />} />
                <Route path="/our-history" element={<HistoryPage />} />
                <Route path="/message-from/:role" element={<MessageFromChairman />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/events" element={<Events />} />
                <Route path="/admission-requirements" element={<AdmissionRequirements />} />
                <Route path="/login" element={<Login />} />
                <Route path="/registration" element={<Register />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/404" element={<PageNotFound />} />
                <Route path="/coming-soon" element={<ComingSoon />} />
                <Route path="/blog-classic" element={<BlogClassic />} />
                <Route path="/blog-grid" element={<BlogGrid />} />
                <Route path="/mission-vision" element={<MissionVision />} />
                <Route path="/products" element={<Product />} />
                <Route path="/product-details" element={<ProductDetails />} />
                <Route path="/admission-process" element={<AdmissionProcess/>} />
                <Route path="/home-adm-process" element={<PrivateRoute element={<AdmProcess />} />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/*" element={<PageNotFound />} />
            </Routes>
        </AuthProvider>
    );
}

export default function AppWrapper() {
    return (
        <Router>
            <App />
        </Router>
    );
}
