import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/account.js';
import styled from 'styled-components';
import { useAuth } from '../../store/auth.js';
import { useNavigate, useLocation } from 'react-router-dom';
const FormContainer = styled.div`
    background: #f8f9fa;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 0 auto;
    @media (max-width: 768px) {
        padding: 20px;
        max-width: 95%;
    }
`;

const CustomRadioGroup = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;
    flex-wrap: wrap;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const CustomRadio = styled.label`
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 30px;
    border: 2px solid #4e73df;
    background-color: #4e73df;
    color: #fff;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
    cursor: pointer;
    margin-bottom: 10px;
    width: 100%;
    justify-content: center;

    &:hover {
        background-color: #1cc88a;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        transform: translateY(-3px);
    }

    input[type="radio"] {
        appearance: none;
        width: 18px;
        height: 18px;
        border: 2px solid #fff;
        border-radius: 50%;
        position: relative;
        margin-right: 10px;
        cursor: pointer;
        outline: none;
        transition: all 0.3s ease;
    }

    input[type="radio"]::before {
        content: '';
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.3s ease;
    }

    input[type="radio"]:checked::before {
        transform: translate(-50%, -50%) scale(1);
    }

    span {
        font-size: 18px;
        color: #fff;
        cursor: pointer;
    }
`;

const StyledCol = styled(Col)`
    margin-bottom: 20px;
`;

const StyledLabel = styled.label`
    display: block;
    font-size: 16px;
    color: #333;
    margin-bottom: 5px;
`;

const StyledInput = styled.input`
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;

    &:focus {
        border-color: #80bdff;
        box-shadow: 0 0 5px rgba(128, 189, 255, 0.5);
    }
`;

const StyledButton = styled.button`
    padding: 12px 30px;
    font-size: 18px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-top: 20px;

    &:hover {
        background-color: #0056b3;
        transform: scale(1.05);
    }

    @media (max-width: 576px) {
        width: 100%;
        padding: 10px;
        font-size: 16px;
    }
`;

function Register() {
    const navigate = useNavigate();
    const { storeTokenInLS } = useAuth();
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        class: '',
        dated: '',
        studentName: '',
        dob_dd: '',
        dob_mm: '',
        dob_yyyy: '',
        dob_words: '',
        fatherName: '',
        fatherProfession: '',
        motherName: '',
        motherProfession: '',
        guardianName: '',
        guardianProfession: '',
        income: '',
        fatherContact: '',
        motherContact: '',
        fatherQualification: '',
        motherQualification: '',
        residence: '',
        village: '',
        tehsil: '',
        district: '',
        sibling: '',  // This will store "yes" or "no"
        siblingName: '',
        siblingClass: ''
    });

    const [message, setMessage] = useState('');

    // Handle input change
    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };

    // Handle radio button change
    const handleRadioChange = (e) => {
        setFormData({
            ...formData,
            sibling: e.target.value
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prepare the data to match the structure expected by the backend
        const dataToSend = {
            username: formData.username,
            email: formData.email,
            password: formData.password,
            class: formData.class,
            dated: formData.dated,
            student_name: formData.studentName,
            dob: {
                day: formData.dob_dd,
                month: formData.dob_mm,
                year: formData.dob_yyyy,
                in_words: formData.dob_words,
            },
            father_name: formData.fatherName,
            father_profession: formData.fatherProfession,
            mother_name: formData.motherName,
            mother_profession: formData.motherProfession,
            guardian_name: formData.guardianName,
            guardian_profession: formData.guardianProfession,
            monthly_income: formData.income,
            father_contact: formData.fatherContact,
            mother_contact: formData.motherContact,
            father_qualification: formData.fatherQualification,
            mother_qualification: formData.motherQualification,
            residence: formData.residence,
            village: formData.village,
            tehsil: formData.tehsil,
            district: formData.district,
            sibling_studying: formData.sibling === 'yes',
            sibling_details: formData.sibling === 'yes' ? {
                name: formData.siblingName,
                class: formData.siblingClass
            } : {}
        };

        try {
            const response = await fetch('http://localhost:3001/api/auth/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSend),
            });
            
            console.log(response)
            const result = await response.json();

            console.log(result);

            if (response.ok) {
                setMessage(result.msg); // success message
                console.log(result)
                storeTokenInLS(result.token)
                navigate("/home-adm-process?register=success");
                // Handle successful registration (e.g., redirect to another page)
            } else {
                setMessage(result.msg); // error message
            }
            
        } catch (error) {
            console.error('Error:', error);
            setMessage('An error occurred. Please try again.');
        }
    };

    return (
        <Styles>
            <div className="main-wrapper registration-page">
                <HeaderTwo />
                <BreadcrumbBox title="Registration" />
                <section className="registration-area">
                    <Container>
                        <FormContainer>
                            <div className="registration-title text-center">
                                <h3>Registration Form Session: 2025</h3>
                            </div>
                            <form id="form_registration" className="form" onSubmit={handleSubmit}>
                                <Row>
                                    <StyledCol md={6}>
                                        <StyledLabel htmlFor="username">Username</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="Enter username"
                                            id="username"
                                            required
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                    <StyledCol md={6}>
                                        <StyledLabel htmlFor="email">Email</StyledLabel>
                                        <StyledInput
                                            type="email"
                                            placeholder="Enter email"
                                            id="email"
                                            required
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                </Row>
                                <Row>
                                    <StyledCol md={6}>
                                        <StyledLabel htmlFor="password">Password</StyledLabel>
                                        <StyledInput
                                            type="password"
                                            placeholder="Enter password"
                                            id="password"
                                            required
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                    <StyledCol md={6}>
                                        <StyledLabel htmlFor="class">Class in which Admission is sought</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="Enter class"
                                            id="class"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                </Row>
                                <Row>
                                    <StyledCol md={6}>
                                        <StyledLabel htmlFor="dated">Dated</StyledLabel>
                                        <StyledInput
                                            type="date"
                                            id="dated"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                    <StyledCol md={6}>
                                        <StyledLabel htmlFor="studentName">Name of the student</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="Enter student name"
                                            id="studentName"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                </Row>

                                <Row>
                                    <StyledCol xs={4}>
                                        <StyledLabel htmlFor="dob_dd">D.O.B (DD)</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="DD"
                                            id="dob_dd"
                                            maxLength="2"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                    <StyledCol xs={4}>
                                        <StyledLabel htmlFor="dob_mm">D.O.B (MM)</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="MM"
                                            id="dob_mm"
                                            maxLength="2"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                    <StyledCol xs={4}>
                                        <StyledLabel htmlFor="dob_yyyy">D.O.B (YYYY)</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="YYYY"
                                            id="dob_yyyy"
                                            maxLength="4"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                </Row>

                                <Row>
                                    <StyledCol md={12}>
                                        <StyledLabel htmlFor="dob_words">D.O.B In Words</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="In Words"
                                            id="dob_words"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                </Row>

                                <Row>
                                    <StyledCol md={6}>
                                        <StyledLabel htmlFor="fatherName">Father's Name</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="Enter father's name"
                                            id="fatherName"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                    <StyledCol md={6}>
                                        <StyledLabel htmlFor="fatherProfession">Profession</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="Enter father's profession"
                                            id="fatherProfession"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                </Row>
                                <Row>
                                    <StyledCol md={6}>
                                        <StyledLabel htmlFor="motherName">Mother's Name</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="Enter mother's name"
                                            id="motherName"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                    <StyledCol md={6}>
                                        <StyledLabel htmlFor="motherProfession">Profession</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="Enter mother's profession"
                                            id="motherProfession"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                </Row>
                                <Row>
                                    <StyledCol md={6}>
                                        <StyledLabel htmlFor="guardianName">Guardian's Name</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="Enter guardian's name"
                                            id="guardianName"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                    <StyledCol md={6}>
                                        <StyledLabel htmlFor="guardianProfession">Profession</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="Enter guardian's profession"
                                            id="guardianProfession"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                </Row>

                                <Row>
                                    <StyledCol md={6}>
                                        <StyledLabel htmlFor="income">Monthly Income of parents</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="Enter income"
                                            id="income"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                    <StyledCol md={6}>
                                        <StyledLabel htmlFor="fatherContact">Father's Contact No.</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="Enter father's contact no."
                                            id="fatherContact"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                </Row>
                                <Row>
                                    <StyledCol md={6}>
                                        <StyledLabel htmlFor="motherContact">Mother's Contact No.</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="Enter mother's contact no."
                                            id="motherContact"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                    <StyledCol md={6}>
                                        <StyledLabel htmlFor="fatherQualification">Father's Qualification</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="Enter father's qualification"
                                            id="fatherQualification"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                </Row>
                                <Row>
                                    <StyledCol md={6}>
                                        <StyledLabel htmlFor="motherQualification">Mother's Qualification</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="Enter mother's qualification"
                                            id="motherQualification"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                    <StyledCol md={6}>
                                        <StyledLabel htmlFor="residence">Residence</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="Enter residence"
                                            id="residence"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                </Row>

                                <Row>
                                    <StyledCol md={4}>
                                        <StyledLabel htmlFor="village">Village/Town</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="Enter village/town"
                                            id="village"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                    <StyledCol md={4}>
                                        <StyledLabel htmlFor="tehsil">Tehsil</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="Enter tehsil"
                                            id="tehsil"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                    <StyledCol md={4}>
                                        <StyledLabel htmlFor="district">District</StyledLabel>
                                        <StyledInput
                                            type="text"
                                            placeholder="Enter district"
                                            id="district"
                                            onChange={handleInputChange}
                                        />
                                    </StyledCol>
                                </Row>

                                <Row>
                                    <StyledCol md={6}>
                                        <StyledLabel>If any sibling is studying in Pioneer Institute of Learning</StyledLabel>
                                        <CustomRadioGroup>
                                            <CustomRadio htmlFor="sibling_yes">
                                                <input
                                                    type="radio"
                                                    id="sibling_yes"
                                                    name="sibling"
                                                    value="yes"
                                                    onChange={handleRadioChange}
                                                />
                                                <span>Yes</span>
                                            </CustomRadio>
                                            <CustomRadio htmlFor="sibling_no">
                                                <input
                                                    type="radio"
                                                    id="sibling_no"
                                                    name="sibling"
                                                    value="no"
                                                    onChange={handleRadioChange}
                                                />
                                                <span>No</span>
                                            </CustomRadio>
                                        </CustomRadioGroup>
                                    </StyledCol>
                                </Row>

                                {/* Conditionally render sibling's name and class fields */}
                                {formData.sibling === 'yes' && (
                                    <Row>
                                        <StyledCol md={6}>
                                            <StyledLabel htmlFor="siblingName">Name of the Student (if Yes)</StyledLabel>
                                            <StyledInput
                                                type="text"
                                                placeholder="Enter sibling's name"
                                                id="siblingName"
                                                onChange={handleInputChange}
                                            />
                                        </StyledCol>

                                        <StyledCol md={6}>
                                            <StyledLabel htmlFor="siblingClass">Class</StyledLabel>
                                            <StyledInput
                                                type="text"
                                                placeholder="Enter sibling's class"
                                                id="siblingClass"
                                                onChange={handleInputChange}
                                            />
                                        </StyledCol>
                                    </Row>
                                )}

                                <StyledButton type="submit">Register Now</StyledButton>
                            </form>
                            {message && <p>{message}</p>}
                        </FormContainer>
                    </Container>
                </section>

                <FooterTwo />
            </div>
        </Styles>
    );
}

export default Register;
