import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo.js';
import { BreadcrumbBox } from '../../components/common/Breadcrumb.js';
import FooterTwo from '../../components/FooterTwo.js';
import { Styles } from './styles/blogDetails.js';

const MissionVision = () => {
    return (
        <Styles>
            <div className="main-wrapper mission-vision-page">
                
                {/* Header */}
                <HeaderTwo />

                {/* Breadcrumb */}
                <BreadcrumbBox title="Mission & Vision" />

                {/* Mission & Vision Section */}
                <section className="mission-vision-area py-5" style={{ backgroundColor: '#f8f9fa' }}>
                    <Container>
                        <Row className="mb-5">
                            <Col lg="6" className="text-center">
                                <img 
                                    src={process.env.PUBLIC_URL + `/assets/images/mission-and-vision.png`} 
                                    alt="Mission and Vision" 
                                    className="img-fluid rounded shadow-lg" 
                                />
                            </Col>
                            <Col lg="6" className="d-flex align-items-center">
                                <div>
                                    <h2 className="section-title mb-4" style={{ fontSize: '2.5rem', fontWeight: 'bold', color: '#343a40' }}>Our Mission</h2>
                                    <p className="lead text-muted" style={{ fontSize: '1.25rem' }}>At Pioneer Institute, we are committed to dismantling ignorance and fostering an environment of scholastic excellence and moral growth.</p>
                                    <p style={{ fontSize: '1.15rem' }}>We strive to empower the new generation with knowledge, ethics, and the ability to achieve new heights of academic and personal success.</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col lg="6" className="order-lg-2 text-center">
                                <img 
                                    src={process.env.PUBLIC_URL + `/assets/images/7.png`} 
                                    alt="Vision" 
                                    className="img-fluid rounded shadow-lg" 
                                />
                            </Col>
                            <Col lg="6" className="d-flex align-items-center order-lg-1">
                                <div>
                                    <h2 className="section-title mb-4" style={{ fontSize: '2.5rem', fontWeight: 'bold', color: '#343a40' }}>Our Vision</h2>
                                    <p className="lead text-muted" style={{ fontSize: '1.25rem' }}>Our vision is to be a beacon of learning that not only excels academically but also molds students into socially responsible and morally upright citizens.</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col lg="12" className="text-center">
                                <h2 className="section-title mb-4" style={{ fontSize: '2.5rem', fontWeight: 'bold', color: '#343a40' }}>Our Goals</h2>
                                <ul className="list-unstyled d-inline-block text-left mx-auto" style={{ maxWidth: '700px', fontSize: '1.15rem' }}>
                                    <li className="mb-3"><i className="fa fa-check-circle mr-3 text-primary"></i> Achieve academic excellence through a comprehensive curriculum.</li>
                                    <li className="mb-3"><i className="fa fa-check-circle mr-3 text-primary"></i> Foster creativity, critical thinking, and collaboration.</li>
                                    <li className="mb-3"><i className="fa fa-check-circle mr-3 text-primary"></i> Encourage leadership and social responsibility.</li>
                                    <li className="mb-3"><i className="fa fa-check-circle mr-3 text-primary"></i> Promote personal growth through extracurricular activities.</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Footer */}
                <FooterTwo />
            </div>
        </Styles>
    );
};

export default MissionVision;
