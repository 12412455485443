import React from 'react';
import { Navbar, Nav, Dropdown, Image, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../../store/auth';

const StyledNavbar = styled(Navbar)`
    background-color: #333; /* Dark background */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 10px 30px;
    
    .navbar-brand img {
        height: 60px;
        width: auto;
        transition: transform 0.3s ease;
    }

    .navbar-brand img:hover {
        transform: scale(1.1);
    }

    .dropdown-toggle {
        color: #ffffff; /* White text */
        font-weight: 600;
        font-size: 16px;
        padding: 8px 16px;
        border-radius: 50px;
        transition: background-color 0.3s, color 0.3s;
        display: flex;
        align-items: center;
        background-color: #444; /* Slightly lighter background for the dropdown */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-decoration: none;
    }

    .dropdown-toggle:hover {
        color: #ffffff;
        background-color: #555; /* Darker on hover */
    }

    .dropdown-menu {
        border-radius: 12px;
        background-color: #444; /* Dark background for the dropdown */
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }

    .dropdown-item {
        padding: 12px 20px;
        color: #ffffff;
        transition: background-color 0.3s, color 0.3s;
    }

    .dropdown-item:hover {
        background-color: #555;
        color: #ffffff;
    }

    .btn-outline-primary {
        border-radius: 50px;
        padding: 8px 16px;
        font-weight: 600;
        background-color: #007bff; /* Primary button color */
        color: #fff;
        transition: background-color 0.3s, box-shadow 0.3s;
        margin-left: 15px;
        text-decoration: none;
        border: none; /* Remove the border */
    }

    .btn-outline-primary:hover {
        background-color: #0056b3; /* Darker blue on hover */
        color: #fff;
        box-shadow: 0 4px 12px rgba(0, 123, 255, 0.4);
    }

    @media (max-width: 768px) {
        .navbar-brand img {
            height: 50px;
        }

        .dropdown-toggle {
            font-size: 14px;
            padding: 8px 12px;
        }

        .btn-outline-primary {
            padding: 6px 12px;
            font-size: 14px;
            margin-left: 10px;
        }
    }
`;

const LoggedNavbar = () => {
    const { user } = useAuth();
    return (
        <StyledNavbar expand="lg">
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img
                        src={process.env.PUBLIC_URL + "/assets/images/wt-logo.png"}
                        alt="Logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-nav" />
                <Navbar.Collapse id="navbar-nav" className="justify-content-end">
                    <Nav className="align-items-center">
                        <Dropdown align="end">
                            <Dropdown.Toggle variant="link" id="dropdown-basic" className="d-flex align-items-center">
                                <Image
                                    src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                    roundedCircle
                                    width="40"
                                    height="40"
                                    className="me-2"
                                />
                                &nbsp;&nbsp;<span style={{ fontWeight: 'bold', color: '#fff' }}>{user.student_name}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} to="/profile">Profile</Dropdown.Item>
                                <Dropdown.Item as={Link} to="/settings">Settings</Dropdown.Item>
                                <Dropdown.Item as={Link} to="/dashboard">Dashboard</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button variant="outline-primary" as={Link} to="/logout">
                            Logout
                        </Button>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </StyledNavbar>
    );
};

export default LoggedNavbar;
