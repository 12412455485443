import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../store/auth.js";

const Logout = () => {  // Change to const to match export default syntax
    const { LogoutUser } = useAuth();

    useEffect(() => {
        LogoutUser();
    }, [LogoutUser]);

    return <Navigate to="/login" />;
};

export default Logout;  // Use default export
