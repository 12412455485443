import React, { useEffect, useState } from 'react';
import Datas from '../data/course/filter.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/courseFilter.js";

function CourseFilter() {
    const [allItems, setAllItems] = useState([]);
    const [displayItems, setDisplayItems] = useState([]);

    useEffect(() => {
        const buttons = document.querySelector(".filter-btn-list").children;
        const items = document.querySelector(".filter-items").children;

        // Randomly select 6 items for "All Highlights"
        const getRandomItems = (arr, num) => {
            const shuffled = [...arr].sort(() => 0.5 - Math.random());
            return shuffled.slice(0, num);
        };

        // Set the items to display initially
        setAllItems(Datas.dataList);
        setDisplayItems(getRandomItems(Datas.dataList, 6));

        for (let i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener("click", function () {
                for (let j = 0; j < buttons.length; j++) {
                    buttons[j].classList.remove("active");
                }

                this.classList.add("active");
                const target = this.getAttribute("data-target");

                if (target === "all") {
                    setDisplayItems(getRandomItems(Datas.dataList, 6));
                } else {
                    const filteredItems = Datas.dataList.filter(item => item.targetId === target);
                    setDisplayItems(filteredItems);
                }
            });
        }
    }, []);

    return (
        <Styles>
            <section className="course-filter">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="sec-title text-center">
                                <h4>{Datas.secTitle}</h4>
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="filter-btns text-center">
                                <ul className="filter-btn-list list-unstyled list-inline">
                                    <li data-target="all" className="active list-inline-item">All Highlights</li>
                                    <li data-target="school-events" className="list-inline-item">School Events</li>
                                    <li data-target="student_projects" className="list-inline-item">Student Projects</li>
                                    <li data-target="extracurricular" className="list-inline-item">Extracurricular Activities</li>
                                    <li data-target="infrastructure" className="list-inline-item">Infrastructure</li>
                                </ul>

                            </div>

                            <Row className="filter-items">
                                {
                                    displayItems.map((data, i) => (
                                        <Col lg="4" md="6" key={i} data-id={data.targetId}>
                                            <div className="course-item">
                                                <Link to={process.env.PUBLIC_URL + data.courseLink}>
                                                    <div className="course-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${data.imgUrl})` }}>
                                                    </div>
                                                </Link>
                                                <div className="course-content">
                                                    <h6 className="heading"><Link to={process.env.PUBLIC_URL + data.courseLink}>{data.courseTitle}</Link></h6>
                                                    <p className="desc">{data.courseDesc}</p>
                                                </div>
                                            </div>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Col>
                        <Col md="12" className="text-center">
                            <div className="viewall-btn">
                                <Link to={process.env.PUBLIC_URL + "/course-grid"}>View All Highlights</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Styles>
    );
}

export default CourseFilter;
